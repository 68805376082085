(function ({ jQuery: $ }) {
  /*! ===== 여기에 스크립트를 작성해 주세요. ===== */

  /**
   * 내부 링크를 클릭했을 때 점프하는 대신 스무스하게 스크롤합니다.
   */
  $(document).on('click', 'a[href^="#"]', function (event) {
    const targetSelector = this.hash || '.body';
    const target = document.querySelector(targetSelector);

    if (typeof target.scrollIntoView === 'function') {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } else {
      $([document.documentElement, document.body]).animate({
        scrollTop: $(target).offset().top,
      });
    }

    event.preventDefault();
  });

  /**
   * .js-toggle class를 가진 체크박스가 변경될 때, data-target으로 명시된
   * 타겟 엘리먼트에 data-class로 명시된 class를 토글합니다.
   *
      <input type="checkbox" class="js-toggle" data-target="#targetElement" data-class="active" />
      <div id="targetElement">This will be toggled with `active` class</div>
   */
  $(document).on('change', '.js-toggle', function () {
    var $toggler = $(this);
    var $target = $($toggler.data('target'));
    var className = $toggler.data('class');
    var isChecked = $toggler.is(':checked');

    if (!$target.length) {
      console.error(
        'Please specify the element to toggle with `data-toggle` attribute.'
      );
      return;
    }
    if (!className) {
      console.error(
        'Please specify the className to toggle with `data-class` attribute.'
      );
      return;
    }

    $target.toggleClass(className, isChecked);
  });

  /* header */
  $('#menu').on('click', '.header_menu_link', function (event) {
    const $menuItem = $(this);
    const $submenu = $menuItem.next('.header_menu_subgroup');

    if ($submenu.length) {
      event.preventDefault();
      $menuItem.closest('.header_menu_item').toggleClass('active');
    }
  });
})(
  (function (jQuery) {
    requireModule('jQuery', typeof jQuery === 'function');

    function requireModule(name, predicate) {
      if (!predicate) {
        throw new Error(
          `Unable to find ${name} module. Please make sure it is loaded before this script.`
        );
      }
    }

    return { jQuery };
  })(window.jQuery)
);
